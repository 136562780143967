/**
 * This code is protected by intellectual property rights.
 * Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
 * (c) 2020 - 2035, Dr. Ing. h.c. F. Porsche AG.
 */
import { Operator } from '../operator';
import { SubOperator } from '../sub-operator';

export class ChangeRequestInfoOperatorData {
  public name: string;
  public displayName: string;
  public active: boolean;
  public isSubCpo: boolean;

  public static mapOperator(operator: Operator): ChangeRequestInfoOperatorData {
    return {
      active: operator.active,
      name: operator.name,
      displayName: operator.displayName,
      isSubCpo: false
    };
  }

  public static mapSubOperator(subOperator: SubOperator): ChangeRequestInfoOperatorData {
    return {
      active: undefined,
      name: subOperator.name,
      displayName: subOperator.displayName,
      isSubCpo: true
    };
  }
}
