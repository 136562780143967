/**
 * This code is protected by intellectual property rights.
 * Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
 * (c) 2020 - 2035, Dr. Ing. h.c. F. Porsche AG.
 */
import { HttpHeaders } from '@angular/common/http';

export abstract class HeaderEnabled {
  public headers: HttpHeaders;

  protected constructor() {
    // this is the default case. Please do clone and adjust the header when it needs different configurations
    this.headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Accept: 'application/json'
    });
  }
}
