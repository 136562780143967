import { Component, EventEmitter, OnDestroy, OnInit, Output, Renderer2 } from '@angular/core';
import { TableBaseComponent } from 'pcs-commons/data-tables';
import { PermissionAware } from '../../permission-aware';
import { AccessRights } from '../../datatypes/access-rights.enum';
import { SubOperator, SubOperatorColumns } from '../../datatypes/sub-operator';
import { Action } from '../../datatypes/action.enum';
import { FormControl, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { AuthorizationService } from '../../auth/authorization.service';
import { ValidationMessagePipe } from 'pcs-commons/validation';
import { Observable } from 'rxjs';

@Component({
  selector: 'pcs-sub-cpo-table',
  templateUrl: './sub-cpo-table.component.html',
  styleUrls: [
    '../scrollable-table/scrollable-table.component.css',
    '../cpo-table/cpo-table.component.css',
    './sub-cpo-table.component.css'
  ]
})
export class SubCpoTableComponent extends TableBaseComponent implements PermissionAware, OnInit, OnDestroy {
  public readonly reqEditPermission: AccessRights[] = [AccessRights.CPO_AND_PARTNER_EDIT_WEB];
  public readonly energySourceColumn: string = SubOperatorColumns.RENEWABLE_ENERGY;
  public readonly showStationName: string = SubOperatorColumns.SHOW_STATION_NAME;
  public readonly pncColumn: string = SubOperatorColumns.ENABLE_PNC;
  public readonly displayNameColumn: string = SubOperatorColumns.DISPLAY_NAME;
  public readonly rfidColumn: string = SubOperatorColumns.ENABLE_RFID_AUTH;
  public readonly remoteColumn: string = SubOperatorColumns.ENABLE_REMOTE_AUTH;
  public readonly preferredColumn: string = SubOperatorColumns.PREFERRED;

  public readonly colsWithCustomColDef: string[] = [
    this.energySourceColumn,
    this.pncColumn,
    this.displayNameColumn,
    this.showStationName,
    this.rfidColumn,
    this.remoteColumn,
    this.preferredColumn
  ];

  @Output() public toggleRenewableEnergyEvent = new EventEmitter<SubOperator>();
  @Output() public togglePlugAndChargeEvent = new EventEmitter<SubOperator>();
  @Output() public toggleShowStationNameEvent = new EventEmitter<SubOperator>();
  @Output() public updateDisplayNameEvent = new EventEmitter<SubOperator>();
  @Output() public toggleRfidAuthEvent = new EventEmitter<SubOperator>();
  @Output() public toggleRemoteAuthEvent = new EventEmitter<SubOperator>();
  @Output() public togglePreferredEvent = new EventEmitter<SubOperator>();

  public editAllowed = false;

  public mode: Action = null;
  public subOperatorInEdit: number = null;
  public displayNameControl = new FormControl('', [Validators.maxLength(100)]);
  public displayNameMaxLength = 100;

  public updateOnProcess = false;

  public updated: Date = null;

  constructor(
    private translate: TranslateService,
    private authorizationService: AuthorizationService,
    public validationPipe: ValidationMessagePipe,
    renderer: Renderer2
  ) {
    super(renderer);
  }

  public get editMode(): boolean {
    return this.mode && Action.EDIT === this.mode;
  }

  public ngOnInit(): void {
    this.editAllowed = this.authorizationService.hasPermission(this.reqEditPermission);
    this.toUnsubscribe.push(this.translate.onLangChange.subscribe(() => (this.updated = new Date())));
    // whenever the data changes, cancel the update. Can happen for example when the display name has been updated
    this.toUnsubscribe.push(this.data.subscribe({ next: () => this.cancelUpdateDisplayName() }));
  }

  public toggleRenewableEnergy(subOperator: SubOperator): void {
    this.toggleRenewableEnergyEvent.emit(subOperator);
  }

  public togglePlugAndCharge(subOperator: SubOperator): void {
    this.togglePlugAndChargeEvent.emit(subOperator);
  }

  public toggleShowStationName(subOperator: SubOperator): void {
    this.toggleShowStationNameEvent.emit(subOperator);
  }

  public toggleRfidAuth(subOperator: SubOperator): void {
    this.toggleRfidAuthEvent.emit(subOperator);
  }

  public toggleRemoteAuth(subOperator: SubOperator): void {
    this.toggleRemoteAuthEvent.emit(subOperator);
  }

  public togglePreferred(subOperator: SubOperator): void {
    this.togglePreferredEvent.emit(subOperator);
  }

  public updateDisplayName(subOperatorToEdit: SubOperator): void {
    if (subOperatorToEdit.displayName === this.displayNameControl.value) {
      // we don't need to make any server call as no changes has been made, treat it as a cancel event
      this.cancelUpdateDisplayName();
      return;
    }
    const updatedSubOperator = subOperatorToEdit;
    updatedSubOperator.displayName = this.displayNameControl.value;
    this.updateDisplayNameEvent.emit(updatedSubOperator);
  }

  public cancelUpdateDisplayName(): void {
    this.mode = null;
    this.subOperatorInEdit = null;
    this.updateOnProcess = false;
  }

  public enableEditForSubOperator(toEdit: SubOperator): void {
    this.mode = Action.EDIT;
    this.subOperatorInEdit = toEdit.id;
    this.displayNameControl.setValue(toEdit.displayName);
  }

  public subOperatorIsInEdit(subOperator: SubOperator): boolean {
    return this.editMode && this.subOperatorInEdit && this.subOperatorInEdit === subOperator.id;
  }

  public getError(control: FormControl): Observable<any> {
    return this.validationPipe.transform(control);
  }

  public updateDisplayNameOngoing(subOperator: SubOperator): boolean {
    return this.updateOnProcess && subOperator.id === this.subOperatorInEdit;
  }

  public disableEditUpdateName(): boolean {
    return !this.editAllowed || this.updateOnProcess;
  }
}
