/**
 * This code is protected by intellectual property rights.
 * Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
 * (c) 2020 - 2035, Dr. Ing. h.c. F. Porsche AG.
 */
import { Component, OnDestroy } from '@angular/core';
import { ChangeRequestDto } from '../../datatypes/audit-flow/change-request-dto';
import { ChangeRequestPayloadType } from '../../datatypes/inbox/change-request-payload-type';
import { ChangeRequestSharedDataService } from '../../services/change-request-shared-data.service';
import { ChangeRequestAction } from '../../datatypes/audit-flow/change-request-action';
import { CDRBillingDto } from '../../datatypes/CDRBillingDto';
import { Subscription } from 'rxjs';

@Component({
  selector: 'pcs-change-request-info',
  templateUrl: './change-request-info.component.html',
  styleUrls: ['./change-request-info.component.css']
})
export class ChangeRequestInfoComponent implements OnDestroy {
  public changeRequest!: ChangeRequestDto;
  public cdr: CDRBillingDto;
  public toUnsub: Subscription[] = [];
  private jsonDiffTypes = [
    ChangeRequestPayloadType.PRODUCT,
    ChangeRequestPayloadType.USER_GROUP,
    ChangeRequestPayloadType.USER,
    ChangeRequestPayloadType.CHARGEPOINT
  ];

  constructor(private changeRequestSharedDataService: ChangeRequestSharedDataService) {
    this.toUnsub.push(
      changeRequestSharedDataService.getCurrentChangeRequestObservable().subscribe((changeRequest) => {
        this.changeRequest = changeRequest;
        if (this.changeRequest?.payloadObjectType === ChangeRequestPayloadType.CDR) {
          this.toUnsub.push(changeRequestSharedDataService.getCurrentCdrObservable().subscribe((cdr) => (this.cdr = cdr)));
        }
      })
    );
  }

  public isCPOOrSubCPOStatusChange(): boolean {
    return (
      this.changeRequest?.payloadObjectType === ChangeRequestPayloadType.CPO ||
      this.changeRequest?.payloadObjectType === ChangeRequestPayloadType.SUB_CPO
    );
  }

  public isCDRBillrunRevocation(): boolean {
    return this.changeRequest?.action === ChangeRequestAction.REMOVE_CDR_FROM_BILLRUN;
  }

  public isReimbursement(): boolean {
    return this.changeRequest?.action === ChangeRequestAction.REIMBURSEMENT;
  }

  public canOnlyBeShownAsJsonDiff(): boolean {
    return (
      (this.changeRequest?.payloadObjectType === ChangeRequestPayloadType.INVOICE &&
        this.changeRequest?.action === ChangeRequestAction.CORRECTION) ||
      this.jsonDiffTypes.includes(this.changeRequest?.payloadObjectType)
    );
  }

  public getParams(): string[] {
    if (this.isCDRBillrunRevocation() && this.cdr) {
      return [this.cdr.sessionId, this.cdr.contractId];
    } else if (this.isReimbursement()) {
      const invoice = JSON.parse(this.changeRequest.updatedValue);
      return [invoice.contractId, invoice.fleetId];
    }
    return [];
  }

  public ngOnDestroy(): void {
    this.toUnsub.forEach((sub) => sub.unsubscribe());
  }
}
