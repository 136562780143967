<!-- This code is protected by intellectual property rights. Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use. (c) 2020 - 2035, Dr. Ing. h.c. F. Porsche AG. -->
<div *ngIf="data">
  <mat-table #table [dataSource]="tableDataSource" class="padding-lr-24">
    <div *ngFor="let col of dataColumns; let columnIndex = index">
      <ng-container *ngIf="col !== actionColumn && col!=='inclusivePlan'" matColumnDef="{{col}}">
        <mat-header-cell *matHeaderCellDef>{{ getI18nBase(col) | translate }}</mat-header-cell>
        <mat-cell *matCellDef="let element "> {{ element[col] }}</mat-cell>
      </ng-container>
      <ng-container *ngIf="col === 'inclusivePlan'" matColumnDef="{{col}}">
        <mat-header-cell *matHeaderCellDef>{{ getI18nBase(col) | translate }}</mat-header-cell>
        <mat-cell
          *matCellDef="let element "> {{ 'product-detail.label.inclusivePlan.' + element[col] | translate }}</mat-cell>
      </ng-container>
    </div>
    <mat-header-row *matHeaderRowDef="dataColumns"></mat-header-row>
    <mat-row *matRowDef="let row; columns: dataColumns;"></mat-row>
  </mat-table>
</div>
