/**
 * This code is protected by intellectual property rights.
 * Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
 * (c) 2020 - 2035, Dr. Ing. h.c. F. Porsche AG.
 */
import { Component, EventEmitter, Input, OnChanges, OnDestroy, Output, Renderer2, SimpleChanges } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { Observable, Subscription } from 'rxjs';

@Component({
  selector: 'app-table-base',
  template: ''
})
/**
 * Please prefer to use OptimizedTableBaseComponent as it used improved methods that gives better performance in case of data/input changes
 * This is mostly used for basic static tables
 */
export class TableBaseComponent implements OnChanges, OnDestroy {
  public actionColumn = 'tableAction';
  @Input() public dataColumns: string[];
  @Input() public data: Observable<any[]>;
  @Input() public i18nBase: string;
  @Input() public actionButtons: boolean;
  @Input() public disableEdit: boolean;
  @Input() public disableDelete: boolean;

  public dataSubscription: Subscription;
  public toUnsubscribe: Subscription[] = [];
  public tableDataSource = new MatTableDataSource<any>();

  @Output() public editEvent = new EventEmitter<any>();
  @Output() public deleteEvent = new EventEmitter<any>();
  @Output() public readEvent = new EventEmitter<any>();

  constructor(public renderer: Renderer2) {}

  public ngOnChanges(changes: SimpleChanges): void {
    this.handleDataChanges(changes);
    if (changes.actionButtons && changes.actionButtons.currentValue) {
      if (!this.dataColumns.includes(this.actionColumn)) {
        this.dataColumns.push(this.actionColumn);
      }
    }
  }

  public handleDataChanges(changes: SimpleChanges): void {
    if (changes.data) {
      this.unsubscribeData();
      this.dataSubscription = this.data.subscribe((tbData) => {
        this.tableDataSource = new MatTableDataSource(tbData);
      });
    }
  }

  public ngOnDestroy(): void {
    this.unsubscribeData();
    this.toUnsubscribe.forEach((subscription) => subscription.unsubscribe());
  }

  private unsubscribeData(): void {
    if (this.dataSubscription) {
      this.dataSubscription.unsubscribe();
    }
  }

  public deleteElement(data: any): void {
    this.deleteEvent.emit(data);
  }

  public editElement(data: any): void {
    this.editEvent.emit(data);
  }

  public readElement(data: any): void {
    this.readEvent.emit(data);
  }

  public getI18nBase(column: string): string {
    if (!this.i18nBase) {
      return 'tableHeader.' + column;
    }
    return this.i18nBase + '.' + column;
  }

  public changeStyle(event: any): void {
    const elementRef = event.target;
    if (!elementRef) {
      return;
    }
    if (event.type === 'mouseenter') {
      this.renderer.removeClass(elementRef, 'mat-elevation-z0');
    } else {
      this.renderer.addClass(elementRef, 'mat-elevation-z0');
    }
  }
}
