/**
 * This code is protected by intellectual property rights.
 * Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
 * (c) 2020 - 2035, Dr. Ing. h.c. F. Porsche AG.
 */
import { Component, EventEmitter, Input, OnInit, Output, Renderer2 } from '@angular/core';
import { Operator } from '../../datatypes/operator';
import { TranslateService } from '@ngx-translate/core';
import { PermissionAware } from '../../permission-aware';
import { AccessRights } from '../../datatypes/access-rights.enum';
import { FormControl, Validators } from '@angular/forms';
import { Action } from '../../datatypes/action.enum';
import { SharedDataService } from '../../services/shared-data.service';
import { ValidationMessagePipe } from 'pcs-commons/validation';
import { Observable } from 'rxjs';
import { AuditService } from '../../services/audit.service';
import { AuthorizationService } from '../../auth/authorization.service';
import { Platform } from '../../datatypes/platform.enum';
import { OperatorFilter } from '../../datatypes/operator-filter';
import { IconOption } from '../../column-filter/icon-column-filter/icon-column-filter.component';
import { TableBaseComponent } from '../table-base/table-base.component';

@Component({
  selector: 'pcs-cpo-table',
  templateUrl: './cpo-table.component.html',
  styleUrls: ['../scrollable-table/scrollable-table.component.css', './cpo-table.component.css']
})
export class CpoTableComponent extends TableBaseComponent implements PermissionAware, OnInit {
  public readonly reqEditPermission: AccessRights[] = [AccessRights.CPO_AND_PARTNER_EDIT_WEB];
  public readonly activeColumn = Operator.ACTION;
  public readonly energySourceColumn = Operator.RENEWABLE_ENERGY;
  public readonly showStationName = Operator.SHOW_STATION_NAME;
  public readonly pncColumn = Operator.ENABLE_PNC;
  public readonly platformColumn = Operator.PLATFORM;
  public readonly cpoCodeColumn = Operator.CPO_CODE;
  public readonly nameColumn = Operator.NAME;
  public readonly displayNameColumn = Operator.DISPLAY_NAME;
  public readonly activationDateColumn = Operator.ACTIVATION_DATE;
  public readonly rfidColumn = Operator.ENABLE_RFID_AUTH;
  public readonly remoteColumn = Operator.ENABLE_REMOTE_AUTH;
  public readonly preferredColumn = Operator.PREFERRED;
  public readonly subOperatorCount = Operator.SUB_OPERATOR_COUNT;

  public readonly colsWithCustomColDef = [
    this.activeColumn,
    this.energySourceColumn,
    this.pncColumn,
    this.platformColumn,
    this.displayNameColumn,
    this.showStationName,
    this.rfidColumn,
    this.remoteColumn,
    this.preferredColumn,
    this.subOperatorCount
  ];

  public readonly platformColumnValues = Object.values(Platform);
  public readonly activeColumnValues = ['active', 'inactive'];
  public readonly energyColumnValues: IconOption[] = [
    { icon: 'renewable_energy', iconType: 'svg', value: 'true' },
    { icon: 'non_renewable_energy', iconType: 'svg', value: 'false' }
  ];
  public readonly showStationNameColumnValues: IconOption[] = [
    { icon: 'ev_station', iconType: 'mat', iconColor: '#08D850', value: 'true' },
    { icon: 'ev_station', iconType: 'mat', iconColor: '#D9D9D9', value: 'false' }
  ];
  public readonly pncColumnValues: IconOption[] = [
    { icon: 'ic_plugandcharge_green', iconType: 'svg', value: 'true' },
    { icon: 'ic_plugandcharge_grey', iconType: 'svg', value: 'false' }
  ];
  public readonly rfidColumnValues: IconOption[] = [
    { icon: 'branding_watermark', iconType: 'mat', iconColor: '#08D850', value: 'true' },
    { icon: 'branding_watermark', iconType: 'mat', iconColor: '#D9D9D9', value: 'false' }
  ];
  public readonly remoteColumnValues: IconOption[] = [
    { icon: 'smartphone', iconType: 'mat', iconColor: '#08D850', value: 'true' },
    { icon: 'smartphone', iconType: 'mat', iconColor: '#D9D9D9', value: 'false' }
  ];
  public readonly preferredColumnValues: IconOption[] = [
    { icon: 'wb_sunny', iconType: 'mat', iconColor: '#08D850', value: 'true' },
    { icon: 'wb_sunny', iconType: 'mat', iconColor: '#D9D9D9', value: 'false' }
  ];

  @Input() public filterObj: OperatorFilter;
  @Output() public toggleRenewableEnergyEvent = new EventEmitter<Operator>();
  @Output() public togglePlugAndChargeEvent = new EventEmitter<Operator>();
  @Output() public toggleShowStationNameEvent = new EventEmitter<Operator>();
  @Output() public updateDisplayNameEvent = new EventEmitter<Operator>();
  @Output() public toggleRfidAuthEvent = new EventEmitter<Operator>();
  @Output() public toggleRemoteAuthEvent = new EventEmitter<Operator>();
  @Output() public togglePreferredEvent = new EventEmitter<Operator>();
  @Output() public filterEvent = new EventEmitter<OperatorFilter>();
  @Output() public showSubOperatorsEvent = new EventEmitter<Operator>();

  public editAllowed = false;

  public mode: Action = null;
  public operatorInEdit: number = null;
  public displayNameControl = new FormControl('', [Validators.maxLength(100)]);
  public displayNameMaxLength = 100;

  public updateOnProcess = false;

  public updated: Date = null;

  constructor(
    private translate: TranslateService,
    private authorizationService: AuthorizationService,
    private dataService: SharedDataService,
    public validationPipe: ValidationMessagePipe,
    private auditService: AuditService,
    renderer: Renderer2
  ) {
    super(renderer);
    this.disableDelete = true;
    this.disableEdit = true;
  }

  public ngOnInit(): void {
    this.editAllowed = this.authorizationService.hasPermission(this.reqEditPermission);
    this.toUnsubscribe.push(this.translate.onLangChange.subscribe(() => (this.updated = new Date())));
    this.toUnsubscribe.push(
      this.dataService.getOperatorUpdateOnProcessObservable().subscribe({
        next: (isCurrentlyOnProcess) => {
          if (this.updateOnProcess && !isCurrentlyOnProcess) {
            this.mode = null;
            this.operatorInEdit = null;
          }
          this.updateOnProcess = isCurrentlyOnProcess;
        }
      })
    );
  }

  public async toggleActiveStatus(operator: Operator): Promise<void> {
    await this.auditService.initiateChangeRequestForCPOActivationOrDeactivation(operator);
  }

  public toggleRenewableEnergy(operator: Operator): void {
    this.toggleRenewableEnergyEvent.emit(operator);
  }

  public togglePlugAndCharge(operator: Operator): void {
    this.togglePlugAndChargeEvent.emit(operator);
  }

  public toggleShowStationName(operator: Operator): void {
    this.toggleShowStationNameEvent.emit(operator);
  }

  public toggleRfidAuth(operator: Operator): void {
    this.toggleRfidAuthEvent.emit(operator);
  }

  public toggleRemoteAuth(operator: Operator): void {
    this.toggleRemoteAuthEvent.emit(operator);
  }

  public togglePreferred(operator: Operator): void {
    this.togglePreferredEvent.emit(operator);
  }

  public updateDisplayName(operatorToEdit: Operator): void {
    if (operatorToEdit.displayName === this.displayNameControl.value) {
      // we don't need to make any server call as no changes has been made, treat it as a cancel event
      this.cancelUpdateDisplayName();
      return;
    }
    const updatedOperator = operatorToEdit;
    updatedOperator.displayName = this.displayNameControl.value;
    this.updateDisplayNameEvent.emit(updatedOperator);
  }

  public cancelUpdateDisplayName(): void {
    this.mode = null;
    this.operatorInEdit = null;
  }

  public enableEditForOperator(toEdit: Operator): void {
    this.mode = Action.EDIT;
    this.operatorInEdit = toEdit.id;
    this.displayNameControl.setValue(toEdit.displayName);
  }

  public operatorIsInEdit(operator: Operator): boolean {
    return this.editMode && this.operatorInEdit && this.operatorInEdit === operator.id;
  }

  public get editMode(): boolean {
    return this.mode && Action.EDIT === this.mode;
  }

  public getError(control: FormControl): Observable<any> {
    return this.validationPipe.transform(control);
  }

  public updateDisplayNameOngoing(operator: Operator): boolean {
    return this.updateOnProcess && operator.id === this.operatorInEdit;
  }

  public disableEditUpdateName(): boolean {
    return !this.editAllowed || this.updateOnProcess;
  }

  public goToSubOperatorView(operator: Operator): void {
    console.log('got sub operator request for : ', operator);
    this.showSubOperatorsEvent.emit(operator);
  }

  public handleFilterValueChange(col: string, filterValue: string | boolean): void {
    const oldVal = this.filterObj[col];
    let newVal = filterValue;
    if (typeof filterValue !== 'boolean') {
      newVal = filterValue?.trim() || null;
    }
    if (oldVal !== newVal) {
      this.filterObj[col] = newVal;
      this.filterEvent.emit(this.filterObj);
    }
  }
}
