/**
 * This code is protected by intellectual property rights.
 * Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
 * (c) 2020 - 2035, Dr. Ing. h.c. F. Porsche AG.
 */
import { OperatorExport } from './operator-export';
import { IopPlatformDto } from './iop-platform-dto';

export class Operator {
  public static ACTION = 'active';
  public static RENEWABLE_ENERGY = 'renewableEnergySource';
  public static SHOW_STATION_NAME = 'showStationName';
  public static ENABLE_PNC = 'enablePlugAndCharge';
  public static NAME = 'name';
  public static COUNT = 'count';
  public static SUB_OPERATOR_COUNT = 'subOperatorCount';
  public static CPO_CODE = 'cpoCode';
  public static PLATFORM = 'platform';
  public static DISPLAY_NAME = 'displayName';
  public static ACTIVATION_DATE = 'lastActivationDate';
  public static ENABLE_RFID_AUTH = 'enableRfidAuth';
  public static ENABLE_REMOTE_AUTH = 'enableRemoteAuth';
  public static PREFERRED = 'preferred';

  public static readonly dataColumns: string[] = [
    Operator.ACTION,
    Operator.CPO_CODE,
    Operator.NAME,
    Operator.DISPLAY_NAME,
    Operator.PLATFORM,
    Operator.SUB_OPERATOR_COUNT,
    Operator.COUNT,
    Operator.ACTIVATION_DATE,
    Operator.RENEWABLE_ENERGY,
    Operator.SHOW_STATION_NAME,
    Operator.ENABLE_PNC,
    Operator.ENABLE_RFID_AUTH,
    Operator.ENABLE_REMOTE_AUTH,
    Operator.PREFERRED
  ];

  public id: number;
  public cpoid: string;
  public cpoCode: string;
  public name: string;
  public displayName: string;
  public count: number;
  public subOperatorCount: number;
  public active: boolean;
  public adminLastUpdatedBy: string;
  public adminLastUpdatedDate: string;
  public lastActivationDate: string;
  public renewableEnergySource: boolean;
  public enablePlugAndCharge: boolean;
  public showStationName: boolean;
  public iopPlatformDto: IopPlatformDto;
  public enableRfidAuth: boolean;
  public enableRemoteAuth: boolean;
  public preferred: boolean;

  public static transformToOperatorExport(operators: Operator[]): OperatorExport[] {
    return operators ? operators.map((op) => Operator.toOperatorExport(op)) : [];
  }

  public static toOperatorExport(operator: Operator): OperatorExport {
    const oe = new OperatorExport();
    oe.code = operator.cpoCode;
    oe.chargepoints = operator.count;
    oe.platform = operator.iopPlatformDto?.name;
    oe.name = operator.name;
    oe.active = operator.active ? 'yes' : 'no';
    oe.last_activation_date = operator.lastActivationDate;
    oe.renewable_energy = operator.renewableEnergySource ? 'yes' : 'no';
    oe.pnc_enabled = operator.enablePlugAndCharge ? 'yes' : 'no';
    oe.show_station_name = operator.showStationName ? 'yes' : 'no';
    oe.rfid_auth_enabled = operator.enableRfidAuth ? 'yes' : 'no';
    oe.remote_auth_enabled = operator.enableRemoteAuth ? 'yes' : 'no';
    oe.preferred = operator.preferred ? 'yes' : 'no';
    return oe;
  }

  public static clone(operator: Operator): Operator {
    return JSON.parse(JSON.stringify(operator));
  }
}
