/**
 * This code is protected by intellectual property rights.
 * Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
 * (c) 2020 - 2035, Dr. Ing. h.c. F. Porsche AG.
 */
import { AfterViewInit, Directive, ElementRef, Input, Renderer2 } from '@angular/core';

@Directive({
  selector: '[styledColumn]'
})
export class StyledColumnDirective implements AfterViewInit {
  @Input() public styledColumn: string;

  constructor(
    private el: ElementRef,
    private renderer: Renderer2
  ) {}

  public ngAfterViewInit(): void {
    if (!this.styledColumn) {
      return;
    }
    const styleClass = this.styledColumn + '-cell';
    this.renderer.addClass(this.el.nativeElement, styleClass);
  }
}
