/**
 * This code is protected by intellectual property rights.
 * Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
 * (c) 2020 - 2035, Dr. Ing. h.c. F. Porsche AG.
 */
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ChargepointService } from './http/chargepoint.service';
import { ExcelService } from 'pcs-commons/statistics';
import { Operator } from '../datatypes/operator';
import { OperatorExport } from '../datatypes/operator-export';

@Injectable({
  providedIn: 'root'
})
export class CpoDownloaderService {
  private readonly excelHeaders = OperatorExport.tableColumns;
  public PAGE_SIZE = 500;

  private downloadingCpos = new BehaviorSubject<boolean>(false);
  public downloadingCpos$ = this.downloadingCpos.asObservable();
  private columnWidths = new Map<string, number>();

  constructor(
    private chargepointService: ChargepointService,
    private excelService: ExcelService
  ) {}

  public downloadCPOs(): void {
    this.downloadingCpos.next(true);
    const operators: OperatorExport[] = [];
    this.resetColumnWidths();
    this.downloadPageData(0, operators);
  }

  private resetColumnWidths(): void {
    this.excelHeaders.forEach((column) => {
      this.columnWidths.set(column, column.length);
    });
  }

  public downloadPageData(currentPage: number, operators: OperatorExport[]): void {
    console.log(`Fetching operators: currentPage = ${currentPage}, pageSize = ${this.PAGE_SIZE}`);
    const offset = currentPage * this.PAGE_SIZE;
    this.chargepointService.getOperators(offset, this.PAGE_SIZE + 1).subscribe({
      next: (data) => {
        const convertedData = Operator.transformToOperatorExport(data);
        this.updateColumnWidths(convertedData);
        if (data.length <= this.PAGE_SIZE) {
          operators.push(...convertedData);
          this.exportOperators(operators);
          return;
        }
        // since we request one extra data, we remove it before adding to the list
        convertedData.pop();
        operators.push(...convertedData);
        this.downloadPageData(currentPage + 1, operators);
      },
      error: () => this.downloadingCpos.next(false)
    });
  }

  private updateColumnWidths(data: OperatorExport[]): void {
    data.forEach((cpo) => {
      this.excelHeaders.forEach((column) => {
        let columnData = cpo[column];
        if (!columnData) {
          return;
        }
        columnData = columnData as string;
        const width = this.columnWidths.get(column);
        const dataWidth = columnData.length;
        if (dataWidth > width) {
          this.columnWidths.set(column, dataWidth);
        }
      });
    });
    console.log('Updated column widths: ', this.columnWidths);
  }

  private exportOperators(operators: OperatorExport[]): void {
    console.log('Converting operator list to excel. Number of operators: ', operators.length);
    this.excelService.exportAsExcelFile(
      operators,
      'cpo_report',
      'CPO-list-' + new Date().toISOString().split('T')[0],
      this.excelHeaders.map((column) => column), // make a copy since the xlsx lib for some reason can change this array
      this.columnWidths
    );
    this.downloadingCpos.next(false);
  }

  public isDownloading(): boolean {
    return this.downloadingCpos.getValue();
  }
}
