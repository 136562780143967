/**
 * This code is protected by intellectual property rights.
 * Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
 * (c) 2020 - 2035, Dr. Ing. h.c. F. Porsche AG.
 */
import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, Renderer2, SimpleChanges } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { Observable, Subscription } from 'rxjs';
import { Utils } from 'pcs-commons/utils';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'optimized-table-base',
  template: ''
})
export class OptimizedTableBaseComponent implements OnInit, OnChanges, OnDestroy {
  public defaultI18nBase = 'tableHeader.';
  public actionColumn = 'tableAction';
  @Input() public dataColumns: string[];
  @Input() public data: Observable<any[]>;
  @Input() public i18nBase: string;
  @Input() public actionButtons: boolean;
  @Input() public disableEdit: boolean;
  @Input() public disableDelete: boolean;

  public dataSubscription: Subscription;
  public toUnsubscribe: Subscription[] = [];
  public tableDataSource = new MatTableDataSource<any>();

  @Output() public addEvent = new EventEmitter<any>();
  @Output() public editEvent = new EventEmitter<any>();
  @Output() public deleteEvent = new EventEmitter<any>();
  @Output() public readEvent = new EventEmitter<any>();
  @Output() public searchEvent = new EventEmitter<any>();

  public ngOnInit(): void {
    console.log('ngOnInit');
    this.dataSubscription = this.data.subscribe((newData) => {
      console.log('updating data in table:', this.constructor.name, '. data length: ', newData.length);
      this.tableDataSource.data = newData;
    });
  }

  constructor(public renderer: Renderer2) {}

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.actionButtons && changes.actionButtons.currentValue) {
      console.log(this.constructor.name, ': handling actionButtons  change');
      this.showActionColumn();
    }

    if (changes.actionButtons && false === changes.actionButtons.currentValue) {
      console.log('disabling actionButtons');
      this.hideActionColumn();
    }

    if (changes.dataColumns) {
      this.handleDataColumnChanges();
    }
  }

  public showActionColumn(): void {
    if (this.dataColumns.includes(this.actionColumn)) {
      Utils.removeArrayItem(this.dataColumns, this.actionColumn);
    }
    this.dataColumns.push(this.actionColumn);
  }

  private hideActionColumn(): void {
    if (this.dataColumns.includes(this.actionColumn)) {
      Utils.removeArrayItem(this.dataColumns, this.actionColumn);
    }
  }

  public deleteElement(data: any): void {
    this.deleteEvent.emit(data);
  }

  public editElement(data: any): void {
    this.editEvent.emit(data);
  }

  public readElement(data: any): void {
    this.readEvent.emit(data);
  }

  public addElement(): void {
    this.addEvent.emit();
  }

  public searchElement(data: any): void {
    this.searchEvent.emit(data);
  }

  public getI18nBase(column: string): string {
    if (!this.i18nBase) {
      return this.defaultI18nBase + column;
    }
    return this.i18nBase + '.' + column;
  }

  public changeStyle(event: any): void {
    const elementRef = event.target;
    if (!elementRef || !this.renderer) {
      return;
    }
    if (event.type === 'mouseenter') {
      this.renderer.removeClass(elementRef, 'mat-elevation-z0');
    } else {
      this.renderer.addClass(elementRef, 'mat-elevation-z0');
    }
  }

  public ngOnDestroy(): void {
    console.log(this.constructor.name, ': ngOnDestroy is called');
    if (this.dataSubscription) {
      this.dataSubscription.unsubscribe();
    }
    this.toUnsubscribe.forEach((subscription) => subscription.unsubscribe());
  }

  // make sure the action columns is always at the end
  public handleDataColumnChanges(): void {
    console.log(this.constructor.name, ': handling data column change');
    if (this.dataColumns.includes(this.actionColumn)) {
      Utils.removeArrayItem(this.dataColumns, this.actionColumn);
      this.dataColumns.push(this.actionColumn);
      return;
    }
    if (this.actionButtons) {
      this.dataColumns.push(this.actionColumn);
    }
  }

  public getFromControlNameWithIndex(col: string, index: number): string {
    return Utils.getFormattedFormControlName(col, index);
  }
}
