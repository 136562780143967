<!-- This code is protected by intellectual property rights. Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use. (c) 2020 - 2035, Dr. Ing. h.c. F. Porsche AG. -->
<div class="mat-elevation-z1" id="operators">
  <mat-toolbar class="headerToolbar">
    <span class="headerSpan">{{ 'operators.header' | translate }}</span>
    <button
      mat-raised-button
      class="headerActionBtn icon-only"
      (click)="onDownloadCPOs()"
      [disabled]="exportingCpos"
      *ngIf="dataFound && (reqEditPermission | accessAllowed)"
    >
      <i class="fa fa-download" aria-hidden="true"></i>
    </button>
    <mat-spinner *ngIf="loading || exportingCpos" [diameter]="30" [strokeWidth]="5"
                 class="toolbarSpinner"></mat-spinner>
  </mat-toolbar>
  <pcs-cpo-table
    [data]="observableOfOperators"
    [dataColumns]="cpoDataColumns"
    [i18nBase]="'operators'"
    [filterObj]="operatorFilter"
    (toggleRenewableEnergyEvent)="toggleEnergySource($event)"
    (togglePlugAndChargeEvent)="togglePlugAndCharge($event)"
    (toggleShowStationNameEvent)="toggleShowStationName($event)"
    (updateDisplayNameEvent)="updateDisplayName($event)"
    (toggleRemoteAuthEvent)="toggleRemoteAuth($event)"
    (toggleRfidAuthEvent)="toggleRfidAuth($event)"
    (togglePreferredEvent)="togglePreferred($event)"
    (filterEvent)="onFilterChange($event)"
    (showSubOperatorsEvent)="onShowSubOperators($event)"
  >
  </pcs-cpo-table>
  <mat-paginator (page)="handlePageChange($event)" [length]="resultLength"
                 [pageSizeOptions]="pageSizeOptions"></mat-paginator>
</div>
