/**
 * This code is protected by intellectual property rights.
 * Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
 * (c) 2020 - 2035, Dr. Ing. h.c. F. Porsche AG.
 */
import { Component, OnDestroy } from '@angular/core';
import { ChangeRequestDto } from '../../datatypes/audit-flow/change-request-dto';
import { BehaviorSubject } from 'rxjs';
import { BaseUrls } from '../../datatypes/base-urls.enum';
import { WebConfigRouterService } from '../../services/web-config-router.service';
import { ActivatedRoute } from '@angular/router';
import { UserDataSourceService } from '../../services/user-data-source.service';
import { ChangeRequestService } from '../../services/http/audit/change-request.service';
import {
  ChangeRequestMessageColumns,
  ChangeRequestMessageDto
} from '../../datatypes/audit-flow/change-request-message-dto';
import { TranslateService } from '@ngx-translate/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ChangeRequestMessageType } from '../../datatypes/audit-flow/change-request-message-type';
import { Message } from '../../datatypes/message';
import { ChangeRequestStatus } from '../../datatypes/inbox/change-request-status';
import { ChangeRequestSharedDataService } from '../../services/change-request-shared-data.service';
import { ChangeRequestDetailsService } from '../../services/change-request-details.service';
import { ChangeRequestDetailsMode } from '../../datatypes/inbox/change-request-details-mode';
import { AbstractNotificationHandler } from 'pcs-commons/notification';
import { FormUtils } from 'pcs-commons/utils';

@Component({
  selector: 'pcs-change-request-details',
  templateUrl: './change-request-details.component.html',
  styleUrls: ['./change-request-details.component.css']
})
export class ChangeRequestDetailsComponent extends AbstractNotificationHandler implements OnDestroy {
  public changeRequestId: string;
  public changeRequest: ChangeRequestDto;
  public loading = false;
  public dataColumns = Object.values(ChangeRequestMessageColumns);

  public msgType: FormControl<ChangeRequestMessageType>;
  public comment: FormControl<string>;
  public changeRequestMsgForm: FormGroup;
  public msgTypeList = [];

  public messagesSubject = new BehaviorSubject<ChangeRequestMessageDto[]>([]);
  public messagesResult$ = this.messagesSubject.asObservable();
  public mode = ChangeRequestDetailsMode.INFO;

  constructor(
    private router: WebConfigRouterService,
    private route: ActivatedRoute,
    private userDataSource: UserDataSourceService,
    private changeRequestService: ChangeRequestService,
    private translateService: TranslateService,
    private formBuilder: FormBuilder,
    private changeRequestSharedDataService: ChangeRequestSharedDataService,
    private changeRequestDetailsService: ChangeRequestDetailsService
  ) {
    super();
    this.changeRequestId = this.route.snapshot.queryParamMap.get('changeRequestId');
    this.determineMode();
    this.createFormGroup();
    this.handleChangeRequestDetailsDataChange();
  }

  private determineMode(): void {
    const mode = this.route.snapshot.queryParamMap.get('mode');
    switch (mode) {
      case ChangeRequestDetailsMode.INFO:
        this.mode = ChangeRequestDetailsMode.INFO;
        break;
      case ChangeRequestDetailsMode.EDIT:
        this.mode = ChangeRequestDetailsMode.EDIT;
        break;
      default:
        this.mode = ChangeRequestDetailsMode.INFO;
    }
  }

  public onBack(): void {
    if (this.editMode()) {
      this.router.navigate([BaseUrls.INBOX]);
      return;
    }
    this.router.navigate([BaseUrls.CHANGE_REQUESTS_OVERVIEW], { queryParams: { loadFilterData: true } });
  }

  public allInputsValid(): boolean {
    return this.changeRequestMsgForm.valid;
  }

  private handleChangeRequestDetailsDataChange(): void {
    this.changeRequestSharedDataService.getCurrentChangeRequestObservable().subscribe((changeRequest) => {
      if (changeRequest) {
        this.changeRequest = changeRequest;
        this.msgTypeList = this.changeRequestDetailsService.getAllowedMessageTypesForUser(
          this.changeRequest,
          this.userDataSource.currentUserId
        );
        this.adaptMessagesFormFields();
        this.messagesSubject.next(changeRequest.messages);
      } else {
        this.changeRequest = null;
      }
    });
  }

  private createFormGroup(): void {
    this.msgType = new FormControl(null, Validators.required);
    this.comment = new FormControl(null, [Validators.required, Validators.maxLength(500)]);
    this.changeRequestMsgForm = this.formBuilder.group({
      msgType: this.msgType,
      comment: this.comment
    });
  }

  public sendMessage(): void {
    if (!this.allInputsValid()) {
      const msg = new Message();
      msg.message = 'changeRequest.message.invalid';
      this.showError(msg);
      return;
    }
    this.loading = true;
    const newMessage = this.createNewMessage();
    this.changeRequestService.addMessage(this.changeRequest.id, newMessage).subscribe({
      next: () => {
        this.loadNewMessages();
      },
      error: () => {
        this.loadNewMessages();
      }
    });
  }

  private loadNewMessages(): void {
    this.loading = false;
    this.msgType.reset();
    this.comment.reset();
    this.getChangeRequestDetails();
  }

  private async getChangeRequestDetails(): Promise<void> {
    this.loading = true;
    if (this.editMode()) {
      await this.changeRequestDetailsService.getMyChangeRequestAndAdditionalData(this.changeRequestId);
    } else {
      await this.changeRequestDetailsService.getChangeRequestAndAdditionalData(this.changeRequestId);
    }
    this.loading = false;
  }

  private createNewMessage(): ChangeRequestMessageDto {
    const newMessage = new ChangeRequestMessageDto();
    newMessage.comment = FormUtils.getStringValue(this.changeRequestMsgForm, 'comment');
    newMessage.messageType = this.msgType.value;
    newMessage.senderUserId = this.userDataSource.currentUserId;
    newMessage.senderLogin = this.userDataSource.currentUserLogin;
    return newMessage;
  }

  private adaptMessagesFormFields(): void {
    if (this.changeRequest?.status === ChangeRequestStatus.CANCELED || this.changeRequest?.status === ChangeRequestStatus.DONE) {
      this.msgType.disable();
      this.comment.disable();
      return;
    }
    this.msgType.enable();
    this.comment.enable();
  }

  public ngOnDestroy(): void {
    this.changeRequestSharedDataService.clearChangeRequestSharedDataService();
  }

  public getInitiator(): string {
    return this.changeRequestDetailsService.getInitiator(this.changeRequest)?.login;
  }

  public getStatus(): string {
    return this.translateService.instant('changeRequest.status.' + this.changeRequest?.status);
  }

  public editMode(): boolean {
    return ChangeRequestDetailsMode.EDIT === this.mode;
  }
}
