import { Component, OnInit } from '@angular/core';
import { SubOperatorService } from '../../services/http/sub-operator.service';
import { ActivatedRoute, Router } from '@angular/router';
import { BehaviorSubject, firstValueFrom } from 'rxjs';
import { SubOperator, SubOperatorColumns } from '../../datatypes/sub-operator';
import { AuditService } from '../../services/audit.service';
import { OperatorSearchState } from '../OperatorSearchState';
import { BaseUrls } from '../../datatypes/base-urls.enum';
import { WebConfigRouterService } from '../../services/web-config-router.service';

@Component({
  selector: 'pcs-sub-operators',
  templateUrl: './sub-operators.component.html',
  styleUrl: './sub-operators.component.css'
})
export class SubOperatorsComponent implements OnInit {
  protected subOperatorTableColumns: string[] = [
    SubOperatorColumns.SUB_OPERATOR_ID,
    SubOperatorColumns.NAME,
    SubOperatorColumns.DISPLAY_NAME,
    SubOperatorColumns.CHARGEPOINT_COUNT,
    SubOperatorColumns.RENEWABLE_ENERGY,
    SubOperatorColumns.SHOW_STATION_NAME,
    SubOperatorColumns.ENABLE_PNC,
    SubOperatorColumns.ENABLE_RFID_AUTH,
    SubOperatorColumns.ENABLE_REMOTE_AUTH,
    SubOperatorColumns.PREFERRED
  ];
  private subOperatorsSubject = new BehaviorSubject<SubOperator[]>([]);
  public subOperators$ = this.subOperatorsSubject.asObservable();
  public state: OperatorSearchState;

  constructor(
    private subOperatorService: SubOperatorService,
    private route: ActivatedRoute,
    private routerService: WebConfigRouterService,
    private router: Router,
    private auditService: AuditService
  ) {
    const navigation = this.router.getCurrentNavigation();
    console.log('current state from cpo page:', navigation?.extras?.state);
    if (navigation?.extras?.state) {
      this.state = navigation.extras.state['data'];
    }
  }

  public async ngOnInit(): Promise<void> {
    await this.fetchSubOperatorDataForOperator();
  }

  private async fetchSubOperatorDataForOperator(): Promise<void> {
    const operatorId = this.route.snapshot.paramMap.get('id');
    const subOperators = await firstValueFrom(this.subOperatorService.getSubOperatorsByOperatorId(Number(operatorId)));
    this.subOperatorsSubject.next(subOperators);
    console.log('Got sub operators: ', subOperators);
  }

  public async toggleEnergySource(subOperator: SubOperator): Promise<void> {
    const toUpdate = this.cloneSubOperator(subOperator);
    toUpdate.renewableEnergySource = !toUpdate.renewableEnergySource;
    await this.updateSubOperator(toUpdate);
  }

  public async togglePlugAndCharge(subOperator: SubOperator): Promise<void> {
    const toUpdate = this.cloneSubOperator(subOperator);
    toUpdate.enablePlugAndCharge = !toUpdate.enablePlugAndCharge;
    await this.updateSubOperator(toUpdate);
  }

  public async toggleShowStationName(subOperator: SubOperator): Promise<void> {
    const toUpdate = this.cloneSubOperator(subOperator);
    toUpdate.showStationName = !toUpdate.showStationName;
    await this.updateSubOperator(toUpdate);
  }

  public async updateDisplayName(subOperator: SubOperator): Promise<void> {
    const toUpdate = this.cloneSubOperator(subOperator);
    await this.updateSubOperator(toUpdate);
  }

  public async toggleRemoteAuth(subOperator: SubOperator): Promise<void> {
    const toUpdate = this.cloneSubOperator(subOperator);
    toUpdate.enableRemoteAuth = !toUpdate.enableRemoteAuth;
    await this.updateSubOperator(toUpdate);
  }

  public async toggleRfidAuth(subOperator: SubOperator): Promise<void> {
    const toUpdate = this.cloneSubOperator(subOperator);
    toUpdate.enableRfidAuth = !toUpdate.enableRfidAuth;
    await this.updateSubOperator(toUpdate);
  }

  private cloneSubOperator(subOperator: SubOperator): SubOperator {
    return JSON.parse(JSON.stringify(subOperator));
  }

  private async updateSubOperator(toUpdate: SubOperator): Promise<void> {
    await firstValueFrom(this.subOperatorService.updateSubOperator(toUpdate));
    await this.fetchSubOperatorDataForOperator();
  }

  public async togglePreferred(subOperator: SubOperator): Promise<void> {
    const previousPreferredAttribute = subOperator.preferred;
    const newPreferredAttribute = !subOperator.preferred;
    await this.auditService.initiateChangeRequestForSubCpoToSetPreferredAttribute(
      subOperator,
      previousPreferredAttribute,
      newPreferredAttribute
    );
  }

  public onBack(): void {
    this.routerService
      .navigate([BaseUrls.OPERATORS], {
        state: { data: this.state },
        skipLocationChange: true
      })
      .then(() => {
      });
  }
}
