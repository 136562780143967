import { Injectable } from '@angular/core';
import { HeaderEnabled } from './header-enabled.abstract';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { SubOperator } from '../../datatypes/sub-operator';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SubOperatorService extends HeaderEnabled {
  constructor(private http: HttpClient) {
    super();
  }

  public getSubOperatorsByOperatorId(operatorId: number): Observable<SubOperator[]> {
    return this.http.get<SubOperator[]>(environment.subOperatorUrl + '/by-operator/' + operatorId, {
      headers: this.headers
    });
  }

  public updateSubOperator(subOperator: SubOperator): Observable<void> {
    return this.http.put<void>(environment.subOperatorUrl, subOperator, {
      headers: this.headers
    });
  }

  public getSubOperatorById(subOperatorId: number): Observable<SubOperator> {
    return this.http.get<SubOperator>(environment.subOperatorUrl + '/' + subOperatorId, {
      headers: this.headers
    });
  }
}
