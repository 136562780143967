/**
 * This code is protected by intellectual property rights.
 * Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
 * (c) 2020 - 2035, Dr. Ing. h.c. F. Porsche AG.
 */
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';

export type IconOption = {
  icon: string;
  iconType: 'svg' | 'mat';
  iconColor?: string;
  value: string;
};

@Component({
  selector: 'pcs-icon-column-filter',
  templateUrl: './icon-column-filter.component.html',
  styleUrl: './icon-column-filter.component.css'
})
export class IconColumnFilterComponent implements OnInit {
  @Input() public withEmptyOption: boolean = true;
  @Input() public withUppercaseOptions: boolean = true;
  @Input() public options: IconOption[] = [];
  @Input() public defaultOption: string = '';
  @Input() public preSelectedOption: string = '';
  @Output() public handleSelectChange: EventEmitter<string> = new EventEmitter<string>();

  public selectControl: FormControl;

  public ngOnInit(): void {
    this.selectControl = new FormControl(this.preSelectedOption || '');
    const selectChanges = this.selectControl.valueChanges;
    selectChanges.subscribe((value) => this.handleSelectChange.emit(value));
  }

  public getIconForValue(value: string): string {
    const option = this.options.find((opt) => opt.value === value);
    return option ? option.icon : '';
  }

  public getIconTypeForValue(value: string): string {
    const option = this.options.find((opt) => opt.value === value);
    return option ? option.iconType : '';
  }

  public getIconColorForValue(value: string): string {
    const option = this.options.find((opt) => opt.value === value);
    return option ? option.iconColor : '';
  }
}
