/**
 * This code is protected by intellectual property rights.
 * Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
 * (c) 2020 - 2035, Dr. Ing. h.c. F. Porsche AG.
 */
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ErrorComponent } from 'pcs-commons/error-management';
import { StatisticComponent } from './statistic/statistic.component';
import { OperatorsComponent } from './chargepoint-operators/operators.component';
import { TaxesComponent } from './taxes/taxes.component';
import { AuthGuardService } from './auth/authguard.service';
import { TokenComponent } from './token.component';
import { FleetsComponent } from './fleets/fleets.component';
import { FleetEditComponent } from './fleets/fleet-edit/fleet-edit.component';
import { IdCardsComponent } from './fleets/id-cards/id-cards.component';
import { ConfigurationsComponent } from './configurations/configurations.component';
import { ChargepointsComponent } from './chargepoints/chargepoints.component';
import { LocationDetailsComponent } from './chargepoints/location-details/location-details.component';
import { CardManagementComponent } from './card-management/card-management.component';
import { OcpiPartnersComponent } from './ocpi-partners/ocpi-partners.component';
import { CustomerinfoComponent } from './customerinfo/customerinfo.component';
import { UserManagementComponent } from './user-management/user-management.component';
import { EditUserGroupComponent } from './user-management/user-groups/edit-user-group/edit-user-group.component';
import { DatabaseComponent } from './database/database.component';
import { ProductDetailComponent } from './products/product-details/product-detail.component';
import { ProductsComponent } from './products/products.component';
import { BaseUrls } from './datatypes/base-urls.enum';
import { PurchaseConditionsComponent } from './purchase-conditions/purchase-conditions.component';
import { PurchaseConditionDetailsComponent } from './purchase-conditions/purchase-condition-details/purchase-condition-details.component';
import { CurrencyFactorsComponent } from './currency-factors/currency-factors.component';
import { TariffImportResultComponent } from './purchase-conditions/tariff-import-result/tariff-import-result.component';
import { CdrClearingPoolComponent } from './cdr/cdr-clearing-pool/cdr-clearing-pool.component';
import { InvoicesComponent } from './invoices/invoices.component';
import { ElectricVehicleComponent } from './electric-vehicle/electric-vehicle.component';
import { ChargepointAttributeImportComponent } from './chargepoint-attribute-import/chargepoint-attribute-import.component';
import { ChargepointAttributeImportResultComponent } from './chargepoint-attribute-import/chargepoint-attribute-import-result/chargepoint-attribute-import-result.component';
import { VinCheckResultComponent } from './electric-vehicle/vin-check-result/vin-check-result.component';
import { CdrRevocationComponent } from './cdr-revocation/cdr-revocation.component';
import { environment } from '../../../fleetui/src/environments/environment';
import { LocalLoginPageComponent } from './local-login-page/local-login-page.component';
import { IonityContractDataComponent } from './ionity-contract-data/ionity-contract-data.component';
import { FleetInvoicesOverviewComponent } from './fleets/fleet-invoices-overview/fleet-invoices-overview.component';
import { ContractHistoryComponent } from './contract-history/contract-history.component';
import { InboxComponent } from './inbox/inbox.component';
import { ChangeRequestDetailsComponent } from './inbox/change-request-details/change-request-details.component';
import { Domain } from './datatypes/domain';
import { ChangeRequestsOverviewComponent } from './change-requests-overview/change-requests-overview.component';
import { EnrollmentEaEcComponent } from './enrollment-ea-ec/enrollment-ea-ec.component';
import { productDetailsResolver } from './services/product-details-resolver.service';
import { operatorResolver } from './services/operator-resolver.service';
import { changeRequestDetailsDataResolver } from './services/change-request-details-data-resolver.service';
import { QueryHistoryOverviewComponent } from './query-history-overview/query-history-overview.component';
import { SubOperatorsComponent } from './chargepoint-operators/sub-operators/sub-operators.component';

export const routes: Routes = [
  {
    path: environment.localLoginUrl,
    component: LocalLoginPageComponent
  },
  {
    path: BaseUrls.PRODUCT_DETAIL + '/:code',
    component: ProductDetailComponent,
    canActivate: [AuthGuardService],
    runGuardsAndResolvers: 'always',
    resolve: {
      productDetailsBase: productDetailsResolver
    },
    data: { domains: [Domain.PRODUCTS, Domain.FLEETS] }
  },
  {
    path: BaseUrls.PRODUCT_DETAIL,
    component: ProductDetailComponent,
    canActivate: [AuthGuardService],
    runGuardsAndResolvers: 'always',
    resolve: {
      productDetailsBase: productDetailsResolver
    },
    data: { domains: [Domain.PRODUCTS, Domain.FLEETS] }
  },
  {
    path: BaseUrls.PRODUCTS,
    component: ProductsComponent,
    canActivate: [AuthGuardService],
    runGuardsAndResolvers: 'always',
    data: { domains: [Domain.PRODUCTS, Domain.FLEETS] }
  },
  {
    path: BaseUrls.CURRENCY_FACTOR_OVERVIEW,
    component: CurrencyFactorsComponent,
    canActivate: [AuthGuardService],
    runGuardsAndResolvers: 'always',
    data: { domains: [Domain.PURCHASE_CONDITIONS] }
  },
  {
    path: BaseUrls.PURCHASE_CONDITION + '/:id',
    component: PurchaseConditionDetailsComponent,
    canActivate: [AuthGuardService],
    runGuardsAndResolvers: 'always',
    data: { domains: [Domain.PURCHASE_CONDITIONS] }
  },
  {
    path: BaseUrls.PURCHASE_CONDITION,
    component: PurchaseConditionDetailsComponent,
    canActivate: [AuthGuardService],
    runGuardsAndResolvers: 'always',
    data: { domains: [Domain.PURCHASE_CONDITIONS] }
  },
  {
    path: BaseUrls.PURCHASE_CONDITION_OVERVIEW,
    component: PurchaseConditionsComponent,
    canActivate: [AuthGuardService],
    runGuardsAndResolvers: 'always',
    resolve: {
      operatorBase: operatorResolver
    },
    data: { domains: [Domain.PURCHASE_CONDITIONS] }
  },
  {
    path: BaseUrls.TARIFF_IMPORT_RESULT,
    component: TariffImportResultComponent,
    canActivate: [AuthGuardService],
    runGuardsAndResolvers: 'always',
    data: { domains: [Domain.PURCHASE_CONDITIONS] }
  },
  {
    path: BaseUrls.ERROR + '/:errorcode',
    component: ErrorComponent
  },
  {
    path: BaseUrls.STATISTICS,
    component: StatisticComponent,
    canActivate: [AuthGuardService],
    runGuardsAndResolvers: 'always',
    data: { domains: [Domain.STATISTICS, Domain.FLEETS] }
  },
  {
    path: BaseUrls.TOKEN_HTML,
    component: TokenComponent
  },
  {
    path: BaseUrls.TOKENS,
    component: TokenComponent
  },
  {
    path: BaseUrls.CARD_MANAGEMENT,
    component: CardManagementComponent,
    canActivate: [AuthGuardService],
    runGuardsAndResolvers: 'always',
    data: { domains: [Domain.CUSTOMER_MANAGEMENT] }
  },
  {
    path: BaseUrls.CDR_CLEARING_POOL,
    component: CdrClearingPoolComponent,
    canActivate: [AuthGuardService],
    runGuardsAndResolvers: 'always',
    data: { domains: [Domain.CDR] }
  },
  {
    path: BaseUrls.CHARGEPOINTS,
    component: ChargepointsComponent,
    canActivate: [AuthGuardService],
    runGuardsAndResolvers: 'always',
    data: { domains: [Domain.CHARGEPOINTS] }
  },
  {
    path: BaseUrls.LOCATION_DETAILS,
    component: LocationDetailsComponent,
    canActivate: [AuthGuardService],
    runGuardsAndResolvers: 'always',
    data: { domains: [Domain.CHARGEPOINTS] }
  },
  {
    path: BaseUrls.OPERATORS,
    component: OperatorsComponent,
    canActivate: [AuthGuardService],
    runGuardsAndResolvers: 'always',
    data: { domains: [Domain.CPO_AND_PARTNER] }
  },
  {
    path: BaseUrls.OPERATORS + '/:id',
    component: SubOperatorsComponent,
    canActivate: [AuthGuardService],
    runGuardsAndResolvers: 'always',
    data: { domains: [Domain.CPO_AND_PARTNER] }
  },
  {
    path: BaseUrls.TAXES,
    component: TaxesComponent,
    canActivate: [AuthGuardService],
    runGuardsAndResolvers: 'always',
    data: { domains: [Domain.PRODUCTS] }
  },
  {
    path: BaseUrls.FLEET_CREATE,
    component: FleetEditComponent,
    canActivate: [AuthGuardService],
    runGuardsAndResolvers: 'always',
    data: { domains: [Domain.FLEETS] }
  },
  {
    path: BaseUrls.FLEET_EDIT + '/:id',
    component: FleetEditComponent,
    canActivate: [AuthGuardService],
    runGuardsAndResolvers: 'always',
    data: { domains: [Domain.FLEETS] }
  },
  {
    path: BaseUrls.ID_CARDS + '/:fleetId',
    component: IdCardsComponent,
    canActivate: [AuthGuardService],
    runGuardsAndResolvers: 'always',
    data: { domains: [Domain.FLEETS] }
  },
  {
    path: BaseUrls.FLEET_INVOICES + '/:fleetId',
    component: FleetInvoicesOverviewComponent,
    canActivate: [AuthGuardService],
    runGuardsAndResolvers: 'always',
    data: { domains: [Domain.FLEETS] }
  },
  {
    path: BaseUrls.FLEETS,
    component: FleetsComponent,
    canActivate: [AuthGuardService],
    runGuardsAndResolvers: 'always',
    data: { domains: [Domain.FLEETS] }
  },
  {
    path: BaseUrls.CONFIGURATIONS,
    component: ConfigurationsComponent,
    canActivate: [AuthGuardService],
    runGuardsAndResolvers: 'always',
    data: { domains: [Domain.CPO_AND_PARTNER, Domain.MASTERDATA] }
  },
  {
    path: BaseUrls.OCPI_PARTNERS,
    component: OcpiPartnersComponent,
    canActivate: [AuthGuardService],
    runGuardsAndResolvers: 'always',
    data: { domains: [Domain.CPO_AND_PARTNER, Domain.JOB_EXECUTION] }
  },
  {
    path: BaseUrls.CUSTOMER_INFO,
    component: CustomerinfoComponent,
    canActivate: [AuthGuardService],
    runGuardsAndResolvers: 'always',
    data: { domains: [Domain.CUSTOMER_MANAGEMENT] }
  },
  {
    path: BaseUrls.USER_MANAGEMENT,
    component: UserManagementComponent,
    canActivate: [AuthGuardService],
    runGuardsAndResolvers: 'always',
    data: { domains: [Domain.USER_MANAGEMENT] }
  },
  {
    path: BaseUrls.USER_GROUPS,
    component: EditUserGroupComponent,
    canActivate: [AuthGuardService],
    runGuardsAndResolvers: 'always',
    data: { domains: [Domain.USER_MANAGEMENT] }
  },
  {
    path: BaseUrls.USER_GROUPS + '/:groupName',
    component: EditUserGroupComponent,
    canActivate: [AuthGuardService],
    runGuardsAndResolvers: 'always',
    data: { domains: [Domain.USER_MANAGEMENT] }
  },
  {
    path: BaseUrls.DATABASE,
    component: DatabaseComponent,
    canActivate: [AuthGuardService],
    runGuardsAndResolvers: 'always',
    data: { domains: [Domain.DATABASE] }
  },
  {
    path: BaseUrls.INVOICES,
    component: InvoicesComponent,
    canActivate: [AuthGuardService],
    runGuardsAndResolvers: 'always',
    data: { domains: [Domain.INVOICES] }
  },
  {
    path: BaseUrls.ELECTRIC_VEHICLES,
    component: ElectricVehicleComponent,
    canActivate: [AuthGuardService],
    runGuardsAndResolvers: 'always',
    data: { domains: [Domain.ELECTRIC_VEHICLES] }
  },
  {
    path: BaseUrls.ELECTRIC_VEHICLES + '/vin-check-result',
    component: VinCheckResultComponent,
    canActivate: [AuthGuardService],
    runGuardsAndResolvers: 'always',
    data: { domains: [Domain.ELECTRIC_VEHICLES] }
  },
  {
    path: BaseUrls.CHARGEPOINT_ATTRIBUTE_IMPORT,
    component: ChargepointAttributeImportComponent,
    canActivate: [AuthGuardService],
    runGuardsAndResolvers: 'always',
    data: { domains: [Domain.CHARGEPOINTS] }
  },
  {
    path: BaseUrls.CHARGEPOINT_ATTRIBUTE_IMPORT_RESULT + '/:importType',
    component: ChargepointAttributeImportResultComponent,
    canActivate: [AuthGuardService],
    runGuardsAndResolvers: 'always',
    data: { domains: [Domain.CHARGEPOINTS] }
  },
  {
    path: BaseUrls.CDR_REVOCATION,
    component: CdrRevocationComponent,
    canActivate: [AuthGuardService],
    runGuardsAndResolvers: 'always',
    data: { domains: [Domain.INVOICES] }
  },
  {
    path: BaseUrls.IONITY_CONTRACT_DATA,
    component: IonityContractDataComponent,
    canActivate: [AuthGuardService],
    runGuardsAndResolvers: 'always',
    data: { domains: [Domain.IONITY_CONTRACT_DATA] }
  },
  {
    path: BaseUrls.PLAN_HISTORY,
    component: ContractHistoryComponent,
    canActivate: [AuthGuardService],
    runGuardsAndResolvers: 'always',
    data: { domains: [Domain.CUSTOMER_MANAGEMENT] }
  },
  {
    path: BaseUrls.INBOX,
    component: InboxComponent,
    canActivate: [AuthGuardService],
    runGuardsAndResolvers: 'always'
  },
  {
    path: BaseUrls.CHANGE_REQUEST_DETAILS,
    component: ChangeRequestDetailsComponent,
    canActivate: [AuthGuardService],
    runGuardsAndResolvers: 'always',
    resolve: {
      changeRequestDetailsData: changeRequestDetailsDataResolver
    }
  },
  {
    path: BaseUrls.CHANGE_REQUESTS_OVERVIEW,
    component: ChangeRequestsOverviewComponent,
    canActivate: [AuthGuardService],
    runGuardsAndResolvers: 'always',
    data: { domains: [Domain.CHANGE_REQUESTS_OVERVIEW] }
  },
  {
    path: BaseUrls.ENROLLMENT_OVERVIEW,
    component: EnrollmentEaEcComponent,
    canActivate: [AuthGuardService],
    runGuardsAndResolvers: 'always',
    data: { domains: [Domain.ENROLLMENT_OVERVIEW] }
  },
  {
    path: BaseUrls.QUERY_HISTORY_OVERVIEW,
    component: QueryHistoryOverviewComponent,
    canActivate: [AuthGuardService],
    runGuardsAndResolvers: 'always',
    data: { domains: [Domain.QUERY_HISTORY_OVERVIEW] }
  },
  {
    path: '',
    redirectTo: BaseUrls.PRODUCTS,
    pathMatch: 'full'
  },
  {
    path: '**',
    redirectTo: 'error/404'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { enableTracing: false })],
  exports: [RouterModule]
})
export class AppRoutingModule {}
